import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          CloudOS v3 is awesome!
        </a>
        <div>
          MIAO Environment variable:  {process.env.REACT_APP_MIAO}
        </div>
        <div>
          Hard coded Environment variable:  {process.env.REACT_APP_HARD_CODED_ENV}
        </div>
      </header>
    </div>
  );
}

export default App;
